import linkedinIcon from '@iconify/icons-eva/linkedin-fill';
import { Icon } from '@iconify/react';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import {
  Avatar,
  Box,
  Button,
  Divider,
  LinearProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { ChangeEvent } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useNavigate } from 'react-router-dom';
import { TestTrialPartialFragment } from '../../../@generated/types';

import { useAuth0 } from '@auth0/auth0-react';
import { isCandidate } from '../../../utils/auth';

import {
  formatDateToMonthDayYear,
  formatDateToMonthDayYearTime,
  formatTestTakenTime,
} from '../../../utils/common';
interface ICandidatesTable {
  testTrials: TestTrialPartialFragment[];
  trialResultsBasePath: string;
  page: number;
  limit: number;
  total?: number;
  loading?: boolean;
  onSuccess(message: string): void;
  onError(error: string): void;
  onChangeLimit(limit: number): void;
  onChangePage(page: number): void;
}

export default function CandidatesTable({
  testTrials,
  trialResultsBasePath,
  page,
  limit,
  total,
  loading = false,
  onChangeLimit,
  onChangePage,
}: ICandidatesTable) {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const candidate = isCandidate(user);

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChangeLimit(parseInt(event.target.value, 10));
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    onChangePage(newPage);
  };

  const getValidHref = (url: string) =>
    url.startsWith('http://') || url.startsWith('https://')
      ? url
      : `http://${url}`;

  return (
    <>
      <PerfectScrollbar>
        <Table sx={{ minWidth: 600 }}>
          <TableHead>
            <TableRow>
              <TableCell>Inviter Email</TableCell>
              <TableCell>Test</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Time Taken</TableCell>
              <TableCell>Invited At</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {testTrials.map((testTrial) => {
              if (!testTrial.candidate) return null;
              const {
                id,
                passed,
                requester_id,
                candidate: { email, picture, id: candidateId },
                company,
                test,
                time_taken_seconds,
                invited_at,
                requester,
              } = testTrial;

              const [preTestName, testName] = test.name.split(':');

              return (
                <TableRow
                  hover
                  key={`testTrial${id}`}
                  onClick={() => {
                    navigate(
                      `${trialResultsBasePath}/${candidateId}/tests/${id}`
                    );
                  }}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar
                        alt={requester?.email || email}
                        src={requester?.picture || picture || ''}
                        sx={{
                          width: '2.625rem',
                          height: '2.625rem',
                          mr: '1rem',
                        }}
                      />
                      <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: 'bold', fontSize: 14 }}
                          >
                            {requester?.name && !requester?.name.includes('@')
                              ? requester?.name
                              : requester?.nickname}
                          </Typography>
                          {requester?.linkedin && (
                            <Link
                              href={getValidHref(requester?.linkedin ?? '')}
                              target="_blank"
                              style={{
                                textDecoration: 'none',
                                marginTop: '0.25rem',
                                filter: 'grayscale(1)',
                              }}
                            >
                              <Icon
                                icon={linkedinIcon}
                                width="16"
                                height="16"
                              />
                            </Link>
                          )}
                        </Box>
                        <Typography variant="body2">
                          {requester?.email || email}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      {preTestName ? preTestName + ':' : test.name}
                    </Typography>
                    {testName && (
                      <Typography variant="body2" sx={{ marginTop: 0.2 }}>
                        {testName}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      title={
                        requester_id === candidateId
                          ? 'You created this test attempt by yourself'
                          : 'You were invited for this test by this company'
                      }
                    >
                      <span>
                        {requester_id !== candidateId
                          ? company?.name
                          : 'Self-Started'}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    sx={{
                      color: !!passed ? 'success.main' : 'error.main',
                    }}
                  >
                    {passed === true
                      ? 'Passed'
                      : passed === false
                      ? 'Failed'
                      : ''}
                  </TableCell>
                  <TableCell>
                    {formatTestTakenTime(time_taken_seconds)}
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      title={formatDateToMonthDayYearTime(invited_at)}
                      placement="top"
                    >
                      <span>{formatDateToMonthDayYear(invited_at)}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      title="View details and start the test"
                      placement="top"
                    >
                      <span>
                        <Button
                          size="medium"
                          variant="text"
                          color="primary"
                          endIcon={<ArrowForwardOutlinedIcon />}
                          disabled={passed === true || passed === false}
                          onClick={(event) => {
                            event.stopPropagation();
                            navigate(`/assessments/${test.brokee_id}`);
                          }}
                          sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          Go to Testing
                        </Button>
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </PerfectScrollbar>
      {loading && <LinearProgress sx={{ width: '100%' }} />}
      {!loading && testTrials.length === 0 && (
        <>
          <Typography
            sx={{
              textAlign: 'center',
              p: 2,
              display: 'inline-flex',
              alignItems: 'center',
              gap: 1,
            }}
            variant="subtitle2"
            gutterBottom
          >
            {candidate
              ? `You don't have any test invitations available yet. To get started, check back later for new invitations from your manager.`
              : `You don't have any test history available yet. To get started, explore available tests in the Tests Catalogue or check back later for new invitations.`}
          </Typography>
          <Divider />
        </>
      )}
      {!loading && (
        <TablePagination
          component="div"
          count={total ?? testTrials.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      )}
    </>
  );
}
