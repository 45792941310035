import { useAuth0 } from '@auth0/auth0-react';
import searchFill from '@iconify/icons-eva/search-fill';
import { Icon } from '@iconify/react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  useGetFilterTestQuery,
  useGetTestCatalogueFiltersQuery,
} from '../../../@generated/facadeClient';
import { SubscriptionAlert } from '../../../components/SubscriptionAlert';
import { ESubscriptionFeaturesList } from '../../../constants/features';
import { useBilling } from '../../../hooks/useBilling';
import { useDebounce } from '../../../hooks/useDebounce';
import { getCompanyId, isCompany, isPersonal } from '../../../utils/auth';
import { ITestFilters } from '../interfaces';
import CustomFilter from './CustomFilter';
import { TestCatalog } from './TestCatalog';
import {
  DefaultTValue,
  TOtherFilters,
  TestCatalogueDefaultFilters,
  formatFilterKey,
  getTestFilterQueryVariables,
} from './utils';

const TestsCataloguePageList: FC = () => {
  const { user } = useAuth0();
  const {
    canAccessProFeatures,
    hasActiveFreeTrial,
    canAccessPersonalAccountFeatures,
  } = useBilling();
  const freeTrials = hasActiveFreeTrial();
  const companyId = getCompanyId(user);
  const [filters, setFilters] = useState<ITestFilters>(
    freeTrials
      ? {
          ...TestCatalogueDefaultFilters,
          availability: TOtherFilters.FREE,
          company_id: companyId,
          dynamic_issues: TOtherFilters.SELECT_ALL,
        }
      : { ...TestCatalogueDefaultFilters, company_id: companyId }
  );

  const { data: filtersData } = useGetTestCatalogueFiltersQuery();
  const [searchParams, setSearchParams] = useSearchParams();
  const otherFilters = {
    availability: {
      value: filters.availability,
      label: TOtherFilters.AVAILABILITY,
      defaultValue: TOtherFilters.SELECT_ALL,
      defaultLabel: TOtherFilters.ALL_Plans,
      options: [
        { label: TOtherFilters.PRO_PLAN, value: TOtherFilters.PREMIUM },
        { label: TOtherFilters.FREE_TRIAL, value: TOtherFilters.FREE },
      ],
    },
    dynamic_issues: {
      value: filters.dynamic_issues,
      label: TOtherFilters.ISSUES,
      defaultValue: TOtherFilters.SELECT_ALL,
      defaultLabel: TOtherFilters.SELECT_ALL,
      options: [
        { label: TOtherFilters.DYNAMIC_ISSUES, value: true },
        { label: TOtherFilters.STATIC_ISSUES, value: false },
      ],
    },
    sort_by: {
      value: filters.sort_by,
      label: TOtherFilters.SORT_BY,
      defaultValue: '',
      defaultLabel: '',
      options: [
        { label: TOtherFilters.TNAME_ASC, value: TOtherFilters.ASC },
        { label: TOtherFilters.TNAME_DESC, value: TOtherFilters.DESC },
      ],
    },
  };

  const { state } = useLocation();
  const page = parseInt(
    searchParams.get('page') || DefaultTValue.TESTS_PAGE_0,
    10
  );
  const limit = parseInt(
    searchParams.get('limit') || DefaultTValue.TESTS_LIMIT_5,
    10
  );
  const search = searchParams.get('search') || '';
  const debouncedSearch = useDebounce(search, 1000);

  const { loading, data, error } = useGetFilterTestQuery(
    getTestFilterQueryVariables({
      limit,
      page,
      company_id: companyId,
      filters: filters,
      search: debouncedSearch,
    })
  );
  const handleFilterChange = (
    filterKey: keyof typeof filters,
    value: string
  ) => {
    const newFilters = {
      ...filters,
      [filterKey]: value,
    };

    setSearchParams((prevSearchParams) => {
      prevSearchParams.set('filtersParams', JSON.stringify(newFilters));
      return prevSearchParams;
    });
    handlePageChange(Number(DefaultTValue.TESTS_PAGE_0));
    handleLimitChange(Number(DefaultTValue.TESTS_LIMIT_5));
    setFilters(newFilters);
  };

  const handleLimitChange = (limitBy: number) => {
    setSearchParams((prevSearchParams) => {
      prevSearchParams.set('limit', limitBy.toString());
      return prevSearchParams;
    });
  };

  const handlePageChange = (pageBy: number) => {
    setSearchParams((prevSearchParams) => {
      prevSearchParams.set('page', pageBy.toString());
      return prevSearchParams;
    });
  };

  const updateFiltersAndSearchParams = (newFilters: ITestFilters) => {
    setFilters(newFilters);

    setSearchParams((prevSearchParams) => {
      if (
        JSON.stringify(newFilters) !==
        JSON.stringify(prevSearchParams.get('filtersParams'))
      ) {
        prevSearchParams.set(
          'filtersParams',
          JSON.stringify(newFilters) || 'null'
        );
      }
      return prevSearchParams;
    });
  };

  const handleClearFilters = () => {
    setFilters({ ...TestCatalogueDefaultFilters, company_id: companyId });
  };

  const handleSearchByChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchParams((prev) => {
      prev.set('search', event.target.value);
      prev.set('page', '0');
      return prev;
    });
  };

  useEffect(() => {
    const filtersParams = JSON.parse(
      searchParams.get('filtersParams') || 'null'
    );
    if (filtersParams) {
      updateFiltersAndSearchParams(filtersParams);
    } else if (state?.filtersParams) {
      const params = JSON.parse(state.filtersParams || 'null');
      updateFiltersAndSearchParams(params);
      if (state?.page && state?.limit) {
        handlePageChange(state.page);
        handleLimitChange(state.limit);
      }
    } else {
      updateFiltersAndSearchParams(filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {!canAccessProFeatures() && isCompany(user) && (
        <Box sx={{ width: '100%' }}>
          <SubscriptionAlert
            features={ESubscriptionFeaturesList.TEST_CATALOG_PAGE}
          />
        </Box>
      )}
      {!canAccessPersonalAccountFeatures() && isPersonal(user) && (
        <SubscriptionAlert
          features={ESubscriptionFeaturesList.TEST_CATALOG_PERSONAL_PAGE}
        />
      )}
      {canAccessProFeatures() && isCompany(user) && (
        <Alert severity="info">
          Need a custom scenario? Contact us by email{' '}
          <strong>info@brokee.io</strong> to discuss tailored assessments to
          meet your specific needs.
        </Alert>
      )}
      <Typography
        variant="h3"
        display="block"
        sx={{
          mt: '1rem',
          mb: '3rem',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap-reverse',
        }}
      >
        Test Catalogue
      </Typography>
      <Paper elevation={3}>
        <Grid container sx={{ p: '1rem' }} spacing={2}>
          <Grid item lg={6} xs={12} sm={8}>
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon color="action">
                      <Icon icon={searchFill} />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              label="Search Tests"
              variant="filled"
              value={search}
              onChange={handleSearchByChange}
            />
          </Grid>
          <Grid
            key={2}
            item
            lg={5.85}
            xs={12}
            sm={3}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 1,
              alignItems: 'center',
            }}
          >
            {Object.entries(otherFilters || {}).map(
              ([filterKey, filterOptions]) => (
                <Grid key={filterKey} lg={12} item xs={12} sm={6}>
                  <CustomFilter
                    key={filterKey}
                    label={filterOptions.label}
                    defaultValue={filterOptions.defaultValue}
                    defaultLabel={filterOptions.defaultLabel}
                    value={filterOptions.value}
                    onChange={(event: ChangeEvent<{ value: unknown }>) =>
                      handleFilterChange(
                        filterKey as keyof typeof filters,
                        event.target.value as string
                      )
                    }
                    options={filterOptions.options
                      // eslint-disable-next-line
                      .map((option: any) => ({
                        value: String(option.value),
                        label: option.label,
                      }))
                      .sort((a, b) => a.label.localeCompare(b.label))}
                  />
                </Grid>
              )
            )}
            <Tooltip title="Clear all filters" aria-label="clear filters">
              <IconButton
                id="cleat-test-catalog-filters"
                onClick={handleClearFilters}
                size="medium"
                aria-label="clear-filters"
                sx={{
                  ml: 1,
                }}
              >
                <CloseIcon color="error" />
              </IconButton>
            </Tooltip>
          </Grid>
          {Object.entries(filtersData || {}).map(
            ([filterKey, filterOptions]) => (
              <Grid key={filterKey} item lg={4} xs={12} sm={4}>
                <CustomFilter
                  label={formatFilterKey(filterKey as string)}
                  defaultValue={TOtherFilters.SELECT_ALL}
                  defaultLabel={TOtherFilters.SELECT_ALL}
                  value={String(filters[filterKey as keyof typeof filters])}
                  onChange={(event: ChangeEvent<{ value: unknown }>) =>
                    handleFilterChange(
                      filterKey as keyof typeof filters,
                      event.target.value as string
                    )
                  }
                  options={(Array.isArray(filterOptions) ? filterOptions : [])
                    // eslint-disable-next-line
                    .map((option: any) => ({
                      value: String(option.id),
                      label: option.name ?? option.type ?? option.level,
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label))}
                />
              </Grid>
            )
          )}
        </Grid>
        <TestCatalog
          total={data?.tests_aggregate.aggregate?.count || 0}
          onChangeLimit={handleLimitChange}
          onChangePage={handlePageChange}
          limit={limit}
          page={page}
          error={error}
          loading={loading}
          tests={data?.tests ?? []}
        />
      </Paper>
    </>
  );
};

export default TestsCataloguePageList;
