import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useUpdateTestTrialsMutation } from '../../@generated/facadeClient';
import { TestTrialPartialFragment } from '../../@generated/types';
interface IDeleteDialogProps {
  testTrial: TestTrialPartialFragment;
  onClose(): void;
  onSuccess(message: string): void;
  onError(error: string): void;
}

export default function DeleteDialog({
  testTrial,
  onClose,
  onSuccess,
  onError,
}: IDeleteDialogProps) {
  const [updateTestTrial] = useUpdateTestTrialsMutation();
  const uuid = uuidv4();

  function handleClick() {
    updateTestTrial({
      variables: {
        id: testTrial.id,
        set: {
          submitted_at: null,
          started_at: null,
          deleted_at: null,
          completed_tasks: null,
          submitted: null,
          passed: null,
          completion_details: null,
          time_taken_seconds: null,
          total_tasks: null,
          ai_summary: null,
          evaluating_instance_id: null,
          uuid: uuid,
        },
      },
      onCompleted() {
        onSuccess(`Test have been reset successfully.`);
      },
      onError() {
        onError(`Something went wrong. Please try again.`);
      },
    });
  }

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm" scroll="body">
      <DialogTitle>
        Reset {testTrial.test.name} for user {testTrial.candidate.email}?
      </DialogTitle>
      <DialogContent>
        <DialogContentText variant="subtitle1">
          This action cannot be undone. Do you want to proceed?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          id="trial-cancel-reset"
          onClick={onClose}
          variant="text"
          color="error"
        >
          Cancel
        </Button>
        <Button
          id="trial-reset"
          variant="contained"
          color="primary"
          onClick={handleClick}
        >
          Reset
        </Button>
      </DialogActions>
    </Dialog>
  );
}
