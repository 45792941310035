import { axiosInstance } from '../../utils/axios';

interface ITestReviewProps {
  comments: string | null;
  company_name: string;
  difficulty: string;
  difficulty_id: number;
  expertise_level: string;
  expertise_level_id: number;
  rating: number;
  reviewer_email: string;
  reviewer_name: string;
  test: string;
  trial_id: number | null;
}

export const submitTestReview = async (
  accessToken: string,
  reviewData: ITestReviewProps
): Promise<void> => {
  const { data } = await axiosInstance.post(
    '/api/results/submit-test-review',
    reviewData,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );

  return data;
};
