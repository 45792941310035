import { User, useAuth0 } from '@auth0/auth0-react';
import copyOutline from '@iconify/icons-eva/copy-outline';
import { Icon } from '@iconify/react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from '@mui/material';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { changePassword, inviteUser } from '../utils';

interface IInviteDialogProps {
  user: User;
  onClose(): void;
  onSuccess(message: string): void;
  onError(error: string): void;
}

export default function InviteDialog({
  user,
  onClose,
  onSuccess,
  onError,
}: IInviteDialogProps) {
  const { email = '' } = user;
  const testId = user?.app_metadata?.test_id;
  const [open, setOpen] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { data: inviteUrl = '' } = useQuery<string>(
    'changePassword',
    async () => {
      const token = await getAccessTokenSilently();

      return await changePassword(token, {
        email: email ?? '',
        testId: String(testId) ?? '',
      });
    }
  );
  const invite = useMutation(
    async () => {
      const token = await getAccessTokenSilently();

      await inviteUser(token, email);
    },
    {
      onSuccess: (data) => {
        onSuccess(`Candidate ${email} was successfully invited!`);
      },
      onError: (error: AxiosError) => {
        onError(
          (error?.response?.data as { error: string })?.error ??
            `Something went wrong. Please try again.`
        );
      },
    }
  );

  const handleCopyToClipboardURL = async () => {
    await navigator.clipboard.writeText(inviteUrl ?? '');
    setOpen(true);
  };

  const handleCopyToClipboardEmail = async () => {
    await navigator.clipboard.writeText(email ?? '');
    setOpen(true);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm" scroll="body">
      <DialogTitle>Invite candidate</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          fontSize={14}
          padding={1}
        >
          Send an invite now to notify the candidate by email and give them
          access, or send later at any time.
        </DialogContentText>
        <TextField
          value={email}
          size="small"
          fullWidth
          disabled
          variant="filled"
          label="Email"
          sx={{ mb: '1rem' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  open={open}
                  onClose={handleTooltipClose}
                  followCursor
                  placement="top"
                  title="Copied!"
                >
                  <IconButton onClick={handleCopyToClipboardEmail}>
                    <Icon icon={copyOutline} width={20} height={20} />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          value={inviteUrl}
          size="small"
          fullWidth
          disabled
          variant="filled"
          label="Invite URL"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  open={open}
                  onClose={handleTooltipClose}
                  followCursor
                  placement="top"
                  title="Copied!"
                >
                  <IconButton
                    id="candidate-cope-invitation-url"
                    onClick={handleCopyToClipboardURL}
                  >
                    <Icon icon={copyOutline} width={20} height={20} />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          id="candidate-skip-invitation-url"
          onClick={onClose}
          variant="text"
        >
          Not now
        </Button>
        <Button
          id="candidate-send-invitation-url"
          variant="contained"
          onClick={() => {
            invite.mutate();
          }}
        >
          Send Invite
        </Button>
      </DialogActions>
    </Dialog>
  );
}
