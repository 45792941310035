import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { ProtectedRoute } from '../../components/ProtectedRoute';
import { AssessmentLayout } from '../../layouts/AssessmentLayout';
import { PrivateLayout } from '../../layouts/PrivateLayout';
import { PersonalAccountUpgrade } from '../../company/AccountUpgrade';
import { AssessmentPage } from '../../company/AssessmentPage';
import { AssessmentsProvider } from '../../company/AssessmentPage/AssessmentContext';
import AssessmentResultsPage from '../../company/AssessmentPage/AssessmentResults/AssessmentResults';
import { InfrastructureProvider } from '../../company/AssessmentPage/InfrastructureContext';
import { CandidatesPage } from '../../company/CandidatesPage';
import { DashboardPage } from '../../company/DashboardPage';
import { InvitationLinksPage } from '../../company/InvitationLinksPage';
import InvitationLink from '../../company/InvitationLinksPage/InvitationLinkForm';
import { SettingsPage } from '../../company/SettingsPage';
import { TestsCatalouguePage } from '../../company/TestCatalogues';
import { TrialsHistoryList } from '../../company/TestsHistory';
import OnboardingPage from '../../components/Onboarding/OnboardingPage';
import { ProgressLoader } from '../../components/ProgressLoader';
import { isCandidate, isCompany, isPersonal } from '../../utils/auth';
import { Login } from './Login';
import { SignUp } from './SignUp';
import PublicRecordsPage from '../../components/PublicRecords/PublicRecordsPage';
import { TestInsightsPage } from '../../company/TestInsights';
import { LearningPaths } from '../../company/LearningPaths';

export default function AppRoutes() {
  const { isLoading, user } = useAuth0();

  if (isLoading) {
    return <ProgressLoader />;
  }

  return (
    <Routes>
      <Route path="/signup" element={<SignUp />} />
      <Route path="/login" element={<Login />} />
      <Route path="/invitation-links/:id" element={<InvitationLink />} />
      <Route path="/recordings/:id" element={<PublicRecordsPage />} />

      <Route
        path="candidate"
        element={<ProtectedRoute component={() => <PrivateLayout />} />}
      >
        {isCandidate(user) && (
          <Route path="test-invites/*" element={<TrialsHistoryList />} />
        )}
        {isCandidate(user) && (
          <Route
            path="upgrade-to-personal"
            element={<PersonalAccountUpgrade />}
          />
        )}
      </Route>
      <Route path="onboarding" element={<OnboardingPage />} />
      <Route
        path="company"
        element={
          <ProtectedRoute
            component={() => (
              <>
                <PrivateLayout />
                <OnboardingPage />
              </>
            )}
          />
        }
      >
        {(isCompany(user) || isPersonal(user)) && (
          <Route path="test-insights/*" element={<TestInsightsPage />} />
        )}
        {isCompany(user) && (
          <Route path="dashboard" element={<DashboardPage />} />
        )}
        {isCompany(user) && (
          <Route path="candidates/*" element={<CandidatesPage />} />
        )}
        {!isCandidate(user) && (
          <Route path="tests-catalogue/*" element={<TestsCatalouguePage />} />
        )}
        {isPersonal(user) && (
          <Route path="tests-history/*" element={<TrialsHistoryList />} />
        )}
        {isPersonal(user) && (
          <Route path="learning-paths/*" element={<LearningPaths />} />
        )}
        {!isCandidate(user) && (
          <Route path="settings/*" element={<SettingsPage />} />
        )}
        {isCompany(user) && (
          <Route path="invitation-links" element={<InvitationLinksPage />} />
        )}
      </Route>
      <Route
        path="/assessments/*"
        element={
          <ProtectedRoute
            component={() => (
              <>
                <AssessmentsProvider>
                  <InfrastructureProvider>
                    <AssessmentLayout />
                  </InfrastructureProvider>
                </AssessmentsProvider>
              </>
            )}
          />
        }
      >
        <Route path=":id/*" element={<AssessmentPage />} />
        <Route
          path="*"
          element={<Navigate to="/assessments/:id/description" replace />}
        />
      </Route>
      <Route
        path="/assessments/submission-results/:id"
        element={<ProtectedRoute component={AssessmentResultsPage} />}
      />
      {isCandidate(user) ? (
        <Route
          path="*"
          element={<Navigate to="/candidate/test-invites" replace />}
        />
      ) : isCompany(user) ? (
        <Route
          path="*"
          element={<Navigate to="/company/tests-catalogue" replace />}
        />
      ) : (
        <Route
          path="*"
          element={<Navigate to="/company/learning-paths" replace />}
        />
      )}
    </Routes>
  );
}
