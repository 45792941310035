import { Box, CssBaseline, Theme, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAssessment } from '../../company/AssessmentPage/AssessmentContext';
import { useInfrastructure } from '../../company/AssessmentPage/InfrastructureContext';
import { Navbar } from '../../components/AssessmentNavbar';
import { Sidebar } from '../../components/AssessmentSidebar';
import { ErrorPage } from '../../components/ErrorDialog';
import AssessmentAccessDenied from '../../components/ErrorDialog/AccessDenied';
import PrivateFooter from '../../components/Footer/PrivateFooter';
import { ProblemSelectionSlider } from '../../components/ProblemSelection';
import CircularProgressPage from '../../components/ProgressLoader/ProgressLoader';
import { EEnvironmentType } from '../../constants/assessment';
import {
  ASSESSMENT_DEFAULT_MARGIN,
  ASSESSMENT_DRAWER_WIDTH,
  ASSESSMENT_SMALL_MARGIN,
} from '../../constants/sizes';
import { ASSESSMENT_MENU_ITEMS } from './data';

export default function AssessmentLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.split('/').pop();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [menuItems, setMenuItems] = useState(ASSESSMENT_MENU_ITEMS);
  const {
    assessment,
    loading,
    hasAssessmentAccess,
    handleTrialCreation,
    problemSelection,
    error,
  } = useAssessment();
  const { isInfrastructureCreated, infrastructure } = useInfrastructure();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  useEffect(() => {
    if (lgUp) {
      setSidebarOpen(true);
    } else {
      setSidebarOpen(false);
    }
    setMenuItems((prevItems) =>
      prevItems.map((item) =>
        item.title === 'Environment Summary'
          ? {
              ...item,
              visible:
                (infrastructure?.outputs &&
                  Object.keys(infrastructure.outputs).length > 0) ||
                false,
            }
          : item
      )
    );
  }, [lgUp, infrastructure]);

  if (loading) {
    return <CircularProgressPage />;
  }

  if (!hasAssessmentAccess)
    return <AssessmentAccessDenied onClose={() => navigate('/')} />;

  if (!assessment)
    return (
      <ErrorPage
        title="Unable to Retrieve Assessments"
        onClose={() => navigate('/')}
        message="There are no assessment available with this ID."
      />
    );
  if (error)
    return (
      <ErrorPage
        title="An Error Occurred While Retrieving Assessments"
        onClose={() => navigate('/')}
        message={error.message}
      />
    );

  const handleSidebarOpen = () => {
    setSidebarOpen(true);
  };

  const handleSidebarClose = () => {
    setSidebarOpen(false);
  };

  const handleCreatePersonalTrial = async (selectedProblems: number) => {
    await handleTrialCreation(assessment, selectedProblems);
  };

  const isConsoleType =
    assessment?.environment_type?.type === EEnvironmentType.CONSOLE;
  const isEnvPage = path === 'environment';

  return (
    <>
      {problemSelection && (
        <Box
          sx={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            zIndex: 9999,
            overflow: 'hidden',
          }}
        >
          <ProblemSelectionSlider
            defaultProblemsAmount={assessment.issues_per_test || 0}
            onSubmit={handleCreatePersonalTrial}
          />
        </Box>
      )}
      <Box
        sx={(theme: Theme) => ({
          display: 'flex',
          flex: '1 1 auto',
          maxWidth: '100%',
          p: isMobile
            ? '1rem'
            : !isConsoleType && isInfrastructureCreated && isEnvPage
            ? 0
            : '2rem',
          marginTop: isMobile
            ? '1.5rem'
            : !isConsoleType && isInfrastructureCreated && isEnvPage
            ? 0
            : '3rem',
          marginRight: isMobile
            ? '0.5rem'
            : !isConsoleType && isInfrastructureCreated && isEnvPage
            ? 0
            : '3rem',
          paddingTop: isMobile
            ? '3rem'
            : !isConsoleType && isInfrastructureCreated && isEnvPage
            ? 0
            : '5rem',
          px: isMobile
            ? 0
            : !isConsoleType && isInfrastructureCreated && isEnvPage
            ? 0
            : '5rem',
          marginLeft: isMobile
            ? ASSESSMENT_SMALL_MARGIN
            : isSidebarOpen
            ? `${ASSESSMENT_DRAWER_WIDTH}px`
            : !isConsoleType && isInfrastructureCreated && isEnvPage
            ? ASSESSMENT_SMALL_MARGIN
            : ASSESSMENT_DEFAULT_MARGIN,
          transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          minHeight: '100vh',
        })}
      >
        <Outlet />
      </Box>
      <CssBaseline />
      <Navbar
        open={isSidebarOpen}
        onSidebarOpen={handleSidebarOpen}
        assessment={assessment}
      />
      <Sidebar
        onClose={handleSidebarClose}
        open={isSidebarOpen}
        menuItems={menuItems}
      />
      {!(isInfrastructureCreated && isEnvPage && !isConsoleType) && (
        <Box
          sx={(theme: Theme) => ({
            mt: 'auto',
            marginLeft: isMobile
              ? ASSESSMENT_SMALL_MARGIN
              : isSidebarOpen
              ? `${ASSESSMENT_DRAWER_WIDTH}px`
              : 0,
            transition: theme.transitions.create(['margin', 'width'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
            py: '1rem',
            textAlign: 'center',
          })}
        >
          <PrivateFooter />
        </Box>
      )}
    </>
  );
}
