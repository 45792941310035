import { format, parseISO } from 'date-fns';
import { PROBLEMS_THRESHOLD_PERCENTAGE } from '../constants/common';

export const calculateCompletionPercentage = (
  completedTasks: number,
  totalTasks: number
) => {
  if (totalTasks === 0) return 0;
  const percentage = (completedTasks / totalTasks) * 100;
  return Math.round(percentage);
};

export const getCompletionPercentageColor = (percentage: number) => {
  if (percentage >= 70) return 'success.main';
  if (percentage >= 35) return 'warning.main';
  return 'error.main';
};

export function calculateProblemsThreshold(defaultValue: number): {
  maxThreshold: number;
  minThreshold: number;
} {
  const calculatedIncrement = defaultValue * PROBLEMS_THRESHOLD_PERCENTAGE;
  const minThreshold = Math.floor(defaultValue - calculatedIncrement);
  const maxThreshold = Math.ceil(defaultValue + calculatedIncrement);

  return { minThreshold, maxThreshold };
}

export function convertSecondsToMinutes(seconds: number): number {
  const minutes = Math.floor(seconds / 60);

  return minutes;
}

export const generateSlug = (title: string) =>
  title
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/(^-|-$)+/g, '');

export const getPercentageDifference = (current: number, average: number) => {
  if (average === 0) return 0;
  const difference = Math.abs((current - average) / average) * 100;
  return parseFloat(difference.toFixed(2));
};

export function getTestCompletionStatus(passed: boolean | null | undefined) {
  if (passed === true) {
    return 'Passed';
  } else if (passed === false) {
    return 'Failed';
  } else {
    return 'Pending';
  }
}

export function getTestCompletionStatusColor(
  passed: boolean | null | undefined
) {
  if (passed === true) {
    return 'success.main';
  } else if (passed === false) {
    return 'error.main';
  } else {
    return 'text.secondary';
  }
}

export function replaceTextWithEmojis(text: string) {
  text = text.replace(':white_check_mark:', '✅');
  text = text.replace(':x:', '❌');
  text = text.replace(':stopwatch:', '⏱️');
  return text;
}

export function removeBackticks(str: string) {
  return str.replace(/`/g, '');
}

export function formatRegularRankingPositionDescription(
  ranking_position: number,
  total_passed: number
): string {
  if (total_passed <= 1) return 'You are only one who completed the test';
  const description = `You are better than ${(
    (1 - ranking_position / total_passed) *
    100
  ).toFixed(2)}% of all candidates`;
  return description;
}

export function formatCompanyRankingPositionDescription(
  ranking_position: number,
  total_passed: number
): string {
  if (total_passed <= 1)
    return 'This is only one candidate who completed the test';

  const description = `This candidate is better than ${(
    (1 - ranking_position / total_passed) *
    100
  ).toFixed(2)}% of all candidates`;

  return description;
}

export function sanitizeTestName(testName: string) {
  return testName
    .replace(/[:/\\*?"<>|]/g, '_') // Replace special characters with _
    .replace(/\s+/g, '_') // Replace multiple spaces with a single _
    .replace(/__+/g, '_'); // Replace multiple underscores with a single _
}
// date times

export const formatToReadableDateTime = (dateString: string): string => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
  };

  const formattedDate = date.toLocaleDateString('en-GB', options);

  return formattedDate;
};

export const formatDateToMonthDayYearTime = (isoStr?: string): string => {
  if (isoStr) {
    const date = new Date(isoStr);

    // Format the date as "Dec 8, 2023, 7:17 PM"
    const month = date.toLocaleString('en-US', { month: 'short' });
    const day = date.getDate();
    const year = date.getFullYear();
    const time = date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    });

    return `${month} ${day}, ${year}, ${time}`;
  }
  return 'N/A';
};

export const formatDateToMonthDayYear = (isoStr?: string): string => {
  if (isoStr) {
    return format(parseISO(isoStr), 'MMMM  dd, yyyy');
  }
  return 'N/A';
};

export const formatTestTakenTime = (
  time_taken_seconds?: number
): string | null => {
  if (time_taken_seconds === 0) {
    return '00:00:00';
  }

  if (!time_taken_seconds) {
    return null;
  }

  return [
    Math.floor(time_taken_seconds / 3600),
    Math.floor(time_taken_seconds / 60) % 60,
    time_taken_seconds % 60,
  ]
    .join(':')
    .replace(/\b(\d)\b/g, '0$1');
};

export const isDateExpired = (expirationDate: string): boolean => {
  const now = new Date();
  const expiration = new Date(expirationDate);

  return now > expiration;
};
