import Home from '@mui/icons-material/Home';
import TerminalIcon from '@mui/icons-material/Terminal';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import DescriptionIcon from '@mui/icons-material/Description';
import SummarizeIcon from '@mui/icons-material/Summarize';

export interface IMenuItem {
  href: string;
  icon: JSX.Element;
  title: string;
  visible: boolean;
}

export const ASSESSMENT_MENU_ITEMS = [
  {
    href: '/',
    icon: <Home />,
    title: 'Home',
    visible: true,
  },
  {
    href: '/assessments/:id/description',
    icon: <DescriptionIcon />,
    title: 'Assessment Description',
    visible: true,
  },
  {
    href: '/assessments/:id/diagram',
    icon: <ArchitectureIcon />,
    title: 'Assessment Diagram',
    visible: true,
  },
  {
    href: '/assessments/:id/environment',
    icon: <TerminalIcon />,
    title: 'Working Environment',
    visible: true,
  },
  {
    href: '/assessments/:id/environment/summary',
    icon: <SummarizeIcon />,
    title: 'Environment Summary',
    visible: false,
  },
];
