import { Routes, Route } from 'react-router-dom';
import LearningPathDetails from './Components/LearningPathDetails';
import LearningPathsPage from './Components/LearningPathsPage';

export default function LearningPaths() {
  return (
    <Routes>
      <Route path="/" element={<LearningPathsPage />} />
      <Route path="/:slug" element={<LearningPathDetails />} />
    </Routes>
  );
}
