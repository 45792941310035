import { User, useAuth0 } from '@auth0/auth0-react';
import plusFill from '@iconify/icons-eva/plus-fill';
import searchFill from '@iconify/icons-eva/search-fill';
import { Icon } from '@iconify/react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Snackbar,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  useGetPrivateTestsQuery,
  useSubscribeTestTrialsCountSubscription,
} from '../../../@generated/facadeClient';
import {
  TestTrialCandidatePartialFragment,
  TestTrialPartialFragment,
} from '../../../@generated/types';
import { SubscriptionAlert } from '../../../components/SubscriptionAlert';
import { ESubscriptionFeaturesList } from '../../../constants/features';
import { useBilling } from '../../../hooks/useBilling';
import { useDebounce } from '../../../hooks/useDebounce';
import { getCompanyId } from '../../../utils/auth';
import AddAnotherTestDialog from '../AddAnotherTestDialog';
import DeleteTestTrail from '../DeleteTestDialog';
import DeleteUserDialog from '../DeleteUserDialog';
import ResetTestTrail from '../ResetTestDialog';
import useConditionalTestTrialsQuery from '../hooks/useConditionalTestTrialsQuery';
import {
  ICandidate,
  ISendTestTrailRequestPayload,
  getTestTrialsCountQueryVariables,
  getTestTrialsQueryVariables,
  isValidLimit,
  isValidPage,
  sendTestTrialInvitation,
} from '../utils';
import CandidatesTable from './CandidatesTable';
import CreateDialog from './CreateDialog';
import InviteDialog from './InviteDialog';
import {
  EFilterByResultKeys,
  ISortCriteria,
  defaultLimit,
  defaultPage,
  defaultSortCriteria,
  filterByTestResult,
  parseSortParams,
} from './data';
export default function CandidatesPage() {
  const { getAccessTokenSilently, user } = useAuth0();
  const companyId = getCompanyId(user);

  const [searchParams, setSearchParams] = useSearchParams({
    search: '',
    filterByTest: 'All',
    filterByResult: EFilterByResultKeys.all,
    page: defaultPage.toString(),
    limit: defaultLimit.toString(),
  });
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [inviteUser, setInviteUser] = useState<
    User | TestTrialCandidatePartialFragment | null
  >(null);
  const [deleteUser, setDeleteUser] = useState<TestTrialPartialFragment | null>(
    null
  );
  const [deleteTestTrail, setDeleteTestTrail] =
    useState<TestTrialPartialFragment | null>(null);
  const [resetTestTrail, setResetTestTrail] =
    useState<TestTrialPartialFragment | null>(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [severity, setSeverity] = useState<AlertColor>('success');
  const [message, setMessage] = useState('');
  const [testTrialForNewTest, setTestTrialForNewTest] =
    useState<TestTrialPartialFragment | null>(null);
  const [showAddAnotherTestDialog, setShowAddAnotherTestDialog] =
    useState(false);

  const [sortCriteria, setSortCriteria] = useState<ISortCriteria[]>([]);
  const isValidFilterByResultOption =
    (searchParams.get('filterByResult') || '') in EFilterByResultKeys;

  const page = isValidPage(Number(searchParams.get('page')))
    ? Number(searchParams.get('page'))
    : defaultPage;

  const limit = isValidLimit(Number(searchParams.get('limit')))
    ? Number(searchParams.get('limit'))
    : defaultLimit;

  const search = searchParams.get('search') || '';

  const filterByResult = isValidFilterByResultOption
    ? (searchParams.get('filterByResult') as EFilterByResultKeys)
    : EFilterByResultKeys.all;

  const filterByTest = searchParams.get('filterByTest') || '';

  const debouncedSearch = useDebounce(search, 1000);
  const { data: { tests } = { tests: [] } } = useGetPrivateTestsQuery({
    variables: { company_id: companyId },
  });
  const selectedTestFilterId =
    tests.find((i) => i.name === filterByTest)?.brokee_id.toString() || 'All';
  const { assessment_link_id } = useParams<{ assessment_link_id?: string }>();

  const generalQueryVariables = getTestTrialsQueryVariables({
    limit,
    page,
    search: debouncedSearch,
    sortCriteria: sortCriteria.length > 0 ? sortCriteria : defaultSortCriteria,

    filterByTest: selectedTestFilterId,
    filterByResult,
  });

  const aggregateQueryVariable = getTestTrialsCountQueryVariables({
    search: debouncedSearch,
    sortCriteria: sortCriteria.length > 0 ? sortCriteria : defaultSortCriteria,
    filterByTest: selectedTestFilterId,
    filterByResult,
  });

  const assessmentLinkQueryVariables = {
    ...generalQueryVariables,
    variables: {
      ...generalQueryVariables.variables,
      assessmentLinkId: assessment_link_id,
    },
  };

  const { query, variables } = useConditionalTestTrialsQuery(
    assessment_link_id,
    generalQueryVariables,
    assessmentLinkQueryVariables
  );

  const { data: testTrialsData, loading: testTrialsLoading } = query(variables);
  const { data: testTrialsCountData } = useSubscribeTestTrialsCountSubscription(
    aggregateQueryVariable
  );

  // useEffect(() => {
  //   // search params guard
  //   if (
  //     !isValidPage(Number(searchParams.get('page'))) ||
  //     !isValidLimit(Number(searchParams.get('limit'))) ||
  //     !isValidSortOption ||
  //     !isValidSortCompletionScoreOption ||
  //     !isValidFilterByResultOption
  //   ) {
  //     setSearchParams((prev) => {
  //       !isValidPage(Number(searchParams.get('page'))) &&
  //         prev.set('page', defaultPage.toString());
  //       !isValidLimit(Number(searchParams.get('limit'))) &&
  //         prev.set('limit', defaultLimit.toString());
  //       !isValidSortOption && prev.set('sort', ESortByKeys.invitedAtDown);
  //       !isValidSortOption && prev.set('sort', ESortByKeys.loggedAtDown);
  //       !isValidFilterByResultOption &&
  //         prev.set('filterByResult', EFilterByResultKeys.all);
  //       !isValidSortCompletionScoreOption &&
  //         prev.set(
  //           'sortByCompletionScore',
  //           ESortByCompletionScoreKeys.completionScoreDown
  //         );
  //       return prev;
  //     });
  //   }
  // }, [
  //   searchParams,
  //   setSearchParams,
  //   setSortCriteria,
  //   sortCriteria,
  //   isValidFilterByResultOption,
  //   isValidSortCompletionScoreOption,
  //   isValidSortOption,
  // ]);

  const { mutate: sendTestInvitation } = useMutation(
    async (payload: ISendTestTrailRequestPayload) => {
      const token = await getAccessTokenSilently();
      return await sendTestTrialInvitation(token, payload);
    },
    {
      onSuccess: (data) => {
        handleSuccess('Invitation sent successfully!');
      },
      onError: (error) => {
        handleError(`Something went wrong. Please try again.`);
      },
    }
  );
  const { test_trials: testTrials } = testTrialsData ?? {};

  const testTrialsAggregate = testTrialsCountData?.test_trials_aggregate;
  const total = testTrialsAggregate?.aggregate?.count ?? 0;

  const {
    hasActiveFreeTrial,
    canAccessProFeatures,
    canAccessFreeFeatures,
    hasNoAccess,
  } = useBilling();

  const handleLimitChange = (limitBy: number) => {
    setSearchParams((prev) => {
      prev.set('limit', limitBy.toString());
      return prev;
    });
  };

  const handlePageChange = (pageBy: number) => {
    setSearchParams((prev) => {
      prev.set('page', pageBy.toString());
      return prev;
    });
  };

  const handleSearchByChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchParams((prev) => {
      prev.set('search', event.target.value);
      prev.set('page', '0');
      return prev;
    });
  };

  const handleFilterByTestChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchParams((prev) => {
      prev.set('filterByTest', event.target.value);
      prev.set('limit', '0');
      return prev;
    });
  };

  const handleFilterByResultChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchParams((prev) => {
      prev.set('filterByResult', event.target.value);
      prev.set('limit', '0');
      return prev;
    });
  };

  const handleSortChange = (newSortCriteria: ISortCriteria[]) => {
    setSortCriteria(newSortCriteria);

    setSearchParams((prev) => {
      const params = new URLSearchParams(prev);
      params.delete('sort');
      if (newSortCriteria.length > 0) {
        const sortParam = newSortCriteria
          .map(({ key }) => encodeURIComponent(key))
          .join('&');
        params.set('sort', sortParam);
      }

      return params;
    });
  };
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const sortParam = params.get('sort');

    if (sortParam) {
      const criteria = parseSortParams(sortParam);
      console.log('Parsed sort criteria:', criteria);
      setSortCriteria(criteria);
    } else if (sortCriteria.length === 0) {
      setSortCriteria([]);
    }
  }, [searchParams, setSearchParams, sortCriteria.length]);

  const handleClearFilters = () => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams();
      newParams.set('page', defaultPage.toString());
      newParams.set('limit', defaultLimit.toString());
      newParams.set('search', '');
      newParams.set('filterByTest', 'All');
      newParams.set('filterByResult', EFilterByResultKeys.all);
      newParams.set('sort', '');

      return newParams;
    });
    setSortCriteria([]);
  };

  const handleUserDelete = (testTrial: TestTrialPartialFragment) => {
    setDeleteUser(testTrial);
  };

  const handleTestTrailDelete = (testTrial: TestTrialPartialFragment) => {
    setDeleteTestTrail(testTrial);
  };

  const handleTestTrailReset = (testTrial: TestTrialPartialFragment) => {
    setResetTestTrail(testTrial);
  };

  const handleCreateDialogOpen = () => {
    setShowCreateDialog(true);
  };

  const handleAddAnotherTest = (testTrial: TestTrialPartialFragment) => {
    setShowAddAnotherTestDialog(true);
    setTestTrialForNewTest(testTrial);
  };

  const handleCreateDialogClose = () => {
    setShowCreateDialog(false);
  };

  const handleInviteDialogClose = () => {
    setInviteUser(null);
  };

  const handleDeleteDialogClose = () => {
    setDeleteUser(null);
  };

  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };

  const handleCreateDialogSuccess = (candidate: ICandidate) => {
    const userExists = candidate.user_exists;
    setShowCreateDialog(false);
    if (!userExists) {
      setInviteUser(candidate);
    } else {
      sendTestInvitation({
        companyName: candidate.app_metadata.company_name,
        requesterName: candidate.app_metadata.requester,
        requesterEmail: user?.email ?? '',
        testId: candidate.app_metadata.test_id,
        testName: candidate.app_metadata.test_name,
        recipientEmail: candidate.email,
      });
    }
  };

  const handleAddAnotherTestDialogSuccess = () => {
    setShowAddAnotherTestDialog(false);
    handleSuccess('Test added successfully.');
  };

  const handleInviteDialogSuccess = (message: string) => {
    setInviteUser(null);
    handleSuccess(message);
  };

  const handleDeleteDialogSuccess = (message: string) => {
    setDeleteUser(null);
    handleSuccess(message);
  };

  const handleDeleteTestDialogSuccess = (message: string) => {
    setDeleteTestTrail(null);
    handleSuccess(message);
  };

  const handleResetTestDialogSuccess = (message: string) => {
    setResetTestTrail(null);
    handleSuccess(message);
  };

  const handleSuccess = (message: string) => {
    setShowSnackbar(true);
    setSeverity('success');
    setMessage(message);
  };

  const handleError = (error: string) => {
    setShowSnackbar(true);
    setSeverity('error');
    setMessage(error);
    setShowCreateDialog(false);
    setInviteUser(null);
    setDeleteUser(null);
    setDeleteTestTrail(null);
  };

  return (
    <>
      {!canAccessProFeatures() && (
        <Box sx={{ width: '100%' }}>
          <SubscriptionAlert
            features={ESubscriptionFeaturesList.CANDIDATE_PAGE}
          />
        </Box>
      )}
      <Box
        sx={{
          mt: '1rem',
          mb: '3rem',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap-reverse',
        }}
      >
        <Typography variant="h3" display="block">
          Candidates
        </Typography>
        <Button
          id="candidate-invite-new"
          variant="contained"
          startIcon={<Icon icon={plusFill} />}
          onClick={handleCreateDialogOpen}
          disabled={hasNoAccess()}
        >
          Invite New Candidate
        </Button>
      </Box>
      <Paper elevation={3}>
        <Grid container sx={{ p: '1rem', alignItems: 'center' }} spacing={2}>
          <Grid item xs={12} sm={4} md={5}>
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon color="action">
                      <Icon icon={searchFill} />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              label="Search users"
              variant="filled"
              value={search}
              onChange={handleSearchByChange}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3.25}>
            <TextField
              select
              fullWidth
              label="Test Name"
              value={filterByTest}
              onChange={handleFilterByTestChange}
              SelectProps={{
                native: true,
              }}
            >
              <option value={'All'}>All</option>
              {tests
                ?.filter(({ requires_payment }) =>
                  hasActiveFreeTrial() ? !requires_payment : true
                )
                .map(({ brokee_id, name }) => (
                  <option key={`challenge${brokee_id}`} value={name}>
                    {name}
                  </option>
                ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              select
              fullWidth
              label="Test Result"
              value={filterByResult}
              onChange={handleFilterByResultChange}
              SelectProps={{
                native: true,
              }}
            >
              {filterByTestResult?.map(({ value, label }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            xs={12}
            sm={0.75}
            md={0.75}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <Tooltip title="Clear all filters" aria-label="clear filters">
              <IconButton
                id="cleat-results-filters"
                onClick={handleClearFilters}
                size="medium"
                aria-label="clear filters"
              >
                <CloseIcon color="error" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <CandidatesTable
          testTrials={testTrials ?? []}
          onSuccess={handleSuccess}
          onError={handleError}
          onUserDelete={handleUserDelete}
          onTestDelete={handleTestTrailDelete}
          onAddAnotherTest={handleAddAnotherTest}
          onTestReset={handleTestTrailReset}
          onChangeLimit={handleLimitChange}
          onChangePage={handlePageChange}
          page={page}
          limit={limit}
          total={total}
          loading={testTrialsLoading}
          disableMenuItems={!canAccessFreeFeatures()}
          sortCriteria={sortCriteria}
          onSortChange={handleSortChange}
        />
      </Paper>
      {showAddAnotherTestDialog && testTrialForNewTest && (
        <AddAnotherTestDialog
          testTrial={testTrialForNewTest}
          handleClose={() => setShowAddAnotherTestDialog(false)}
          onSuccess={handleAddAnotherTestDialogSuccess}
          onError={handleError}
        />
      )}
      {showCreateDialog && (
        <CreateDialog
          handleClose={handleCreateDialogClose}
          onSuccess={handleCreateDialogSuccess}
          onError={handleError}
        />
      )}
      {!!inviteUser && (
        <InviteDialog
          user={inviteUser}
          onClose={handleInviteDialogClose}
          onSuccess={handleInviteDialogSuccess}
          onError={handleError}
        />
      )}
      {!!deleteUser && (
        <DeleteUserDialog
          testTrial={deleteUser}
          onClose={handleDeleteDialogClose}
          onSuccess={handleDeleteDialogSuccess}
          onError={handleError}
        />
      )}
      {!!deleteTestTrail && (
        <DeleteTestTrail
          testTrial={deleteTestTrail}
          onClose={() => setDeleteTestTrail(null)}
          onSuccess={handleDeleteTestDialogSuccess}
          onError={handleError}
        />
      )}
      {!!resetTestTrail && (
        <ResetTestTrail
          testTrial={resetTestTrail}
          onClose={() => setResetTestTrail(null)}
          onSuccess={handleResetTestDialogSuccess}
          onError={handleError}
        />
      )}
      {showSnackbar && (
        <Snackbar
          open
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Alert
            variant="filled"
            onClose={handleSnackbarClose}
            severity={severity}
          >
            {message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}
