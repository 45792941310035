import { useAuth0 } from '@auth0/auth0-react';
import { Chip } from '@mui/material';
import { ESubscriptionLabels } from '../../constants/billing';
import { useBilling } from '../../hooks/useBilling';
import { isCompany, isPersonal } from '../../utils/auth';
export default function SubscriptionChip() {
  const { user } = useAuth0();
  const {
    hasActiveFreeTrial,
    hasLimitedAccess,
    canAccessProFeatures,
    canAccessPersonalAccountFeatures,
    isUnlimitedRegularPlan,
  } = useBilling();

  return (
    <>
      {isPersonal(user) &&
        canAccessPersonalAccountFeatures() &&
        !isUnlimitedRegularPlan() && (
          <Chip
            label={ESubscriptionLabels.Personal}
            variant="filled"
            color="info"
            size="small"
          />
        )}
      {isPersonal(user) && isUnlimitedRegularPlan() && (
        <Chip
          label={ESubscriptionLabels.Unlimited}
          variant="filled"
          color="info"
          size="small"
        />
      )}
      {isCompany(user) && canAccessProFeatures() && (
        <Chip
          label={ESubscriptionLabels.Pro}
          variant="filled"
          color="info"
          size="small"
        />
      )}
      {hasActiveFreeTrial() && (
        <Chip
          label={ESubscriptionLabels.Trial}
          variant="filled"
          color="warning"
          size="small"
        />
      )}
      {hasLimitedAccess() && (
        <Chip
          label={ESubscriptionLabels.LimitedAccess}
          variant="filled"
          color="warning"
          size="small"
        />
      )}{' '}
    </>
  );
}
