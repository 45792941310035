import { ArrowForward } from '@mui/icons-material';
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ITrendingTestCardProps } from '../utils';
import UpgradePlanDialog from '../components/UpgradePlanDialog';
import { useBilling } from '../../../hooks/useBilling';
import { useState } from 'react';

export default function TrendingTestCard({ test }: ITrendingTestCardProps) {
  const navigate = useNavigate();
  const { canAccessProFeatures } = useBilling();
  const [showUpgradePlanDialogOpen, setUpgradePlanDialogOpen] = useState(false);

  const handleMoreDetails = (testId: string, payment_required: boolean) => {
    if (canAccessProFeatures() || !payment_required) {
      navigate(`/company/tests-catalogue/test-details/${testId}`, {
        state: { originTitle: 'Test Insights' },
      });
    } else {
      showUpgradePlanDialog();
    }
  };

  const showUpgradePlanDialog = () => {
    setUpgradePlanDialogOpen(true);
  };

  const testNameSpited = test.name?.split(':');

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        '&:hover': {
          boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
          transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
        },
        width: '100%',
        maxWidth: '340px',
        height: '300px',
      }}
    >
      {showUpgradePlanDialogOpen && (
        <UpgradePlanDialog
          open={showUpgradePlanDialogOpen}
          handleClose={() => setUpgradePlanDialogOpen(false)}
          text={`Upgrade your account to get access to ${test.name}!`}
        />
      )}
      <CardHeader
        avatar={
          <Avatar
            variant="rounded"
            src={test.icon as string}
            sx={{ width: '60px', height: '60px' }}
          />
        }
        action={
          <IconButton
            aria-label="see-more"
            onClick={() =>
              handleMoreDetails(test.id.toString(), test.requires_payment)
            }
          >
            <ArrowForward />
          </IconButton>
        }
        title={testNameSpited[0]}
        subheader={testNameSpited[1]}
      />
      <CardContent
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ textOverflow: 'ellipsis' }}
        >
          {test.description}
        </Typography>
      </CardContent>
      <CardActions>
        <Typography variant="body2" color="primary">
          {test.attempts && test.attempts >= 1 && test.completionTime
            ? `Average completion time is ${(
                (test.completionTime?.toFixed(0) as unknown as number) / 60
              ).toFixed(0)} minutes for ${
                test.attempts
              } successful test submissions.`
            : 'Not enough data.'}
        </Typography>
      </CardActions>
    </Card>
  );
}
