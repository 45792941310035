export const PROBLEMS_THRESHOLD_PERCENTAGE = 0.2; // range 0-1

export enum EBadgesStatuses {
  ERROR = 'Sorry, there was an error claiming your badge. Please try again later.',
  AWARDED = 'Your badge has been successfully awarded. Check your email for details or login to Badgr.',
  PREVIOUSLY_AWARDED = 'You have already claimed this badge.',
}

export const EExpertiseLevels = {
  Entry: 1,
  Junior: 2,
  Middle: 3,
  Senior: 4,
};
