import { axiosInstance } from '../../utils/axios';

export interface ClaimBadgeProps {
  available?: boolean;
  badgeName: string;
  onAction: () => void;
}

export interface UseClaimBadgeReturn {
  claimAlertMessage: string;
  claimAlertSeverity: 'success' | 'error';
  claimAlertOpen: boolean;
  claiming: boolean;
  claimBadge: () => void;
  handleClaimAlertClose: () => void;
}
export interface UseClaimBadgeProps {
  badgeId: string;
  recipientEmail: string;
  learning_path_id?: number | null;
  testId: number | null;
  userId: number;
}

export const claimBadge = async (
  accessToken: string,
  {
    badge_id,
    learning_path_id,
    recipient_email,
    test_id,
    user_id,
  }: {
    badge_id: string;
    learning_path_id: number | null;
    recipient_email: string;
    test_id: number | null;
    user_id: number;
  }
): // eslint-disable-next-line
Promise<any> => {
  const response = await axiosInstance.postForm(
    '/api/results/badges/claim',
    {
      badge_id,
      learning_path_id,
      recipient_email,
      test_id,
      user_id,
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
  return response.data;
};

export const copyBadgePublicUrl = (
  publicUrl: string,
  recipientEmail: string,
  setSnackbarOpen: (open: boolean) => void
) => {
  const encodedRecipient = encodeURIComponent(recipientEmail);
  const url = `${publicUrl}?identity__email=${encodedRecipient}`;

  navigator.clipboard
    .writeText(url)
    .then(() => {
      setSnackbarOpen(true);
    })
    .catch((err) => {
      console.error('Failed to copy: ', err);
    });
};
