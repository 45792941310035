import { useAuth0 } from '@auth0/auth0-react';
import arrowBackFill from '@iconify/icons-eva/arrow-back-fill';
import linkedinIcon from '@iconify/icons-eva/linkedin-fill';
import { Icon } from '@iconify/react';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Link as LinkStyle,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import {
  useGetCandidateTrialByIdSubscription,
  useGetPersonalTrialByIdSubscription,
} from '../../../@generated/facadeClient';
import { PersonalTestTrialPartialFragment } from '../../../@generated/types';
import PersonalSubscriptionAlert from '../../../components/SubscriptionAlert/PersonalSubscriptionAlert';
import { isCandidate } from '../../../utils/auth';
import { formatDateToMonthDayYearTime } from '../../../utils/common';
import { CandidatePageDetailsBody } from './CandidateTrialDetailsBody';
import { PersonalPageDetailsBody } from './PersonalTrialDetailsBody';
export default function TestTrialPageDetails() {
  const params = useParams<{ id: string; testId: string }>();
  const navigate = useNavigate();
  const { user } = useAuth0();
  const isCandidateUser = isCandidate(user);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const candidateQuery = useGetCandidateTrialByIdSubscription({
    variables: { id: Number(params.testId) },
    fetchPolicy: 'network-only',
    skip: !isCandidateUser,
  });
  const personalQuery = useGetPersonalTrialByIdSubscription({
    variables: { id: Number(params.testId) },
    fetchPolicy: 'network-only',
    skip: isCandidateUser,
  });

  const data = isCandidateUser ? candidateQuery.data : personalQuery.data;
  const loading = isCandidateUser
    ? candidateQuery.loading
    : personalQuery.loading;

  const testData = data?.test_trials ? data.test_trials[0] : null;
  const candidate = testData?.candidate;
  const { linkedin, picture, email, name } = candidate || {};

  function handleSnackbarClose() {
    setShowSnackbar(false);
  }

  function handleGoToCandidates(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    navigate(-1);
  }

  if (loading)
    return (
      <Box
        sx={{
          h: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress color="primary" />
      </Box>
    );

  if (!testData) {
    return <Navigate to="/company/candidates" />;
  }

  return (
    <Stack sx={{ pt: !isCandidateUser ? '0rem' : '2rem' }} spacing={6}>
      {!isCandidateUser && <PersonalSubscriptionAlert />}
      <LinkStyle
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          textDecoration: 'none',
          color: 'text.primary',
          '&:hover': {
            textDecoration: 'underline',
          },
        }}
        component={Link}
        to={'/company/candidates/'}
        onClick={handleGoToCandidates}
      >
        <Icon
          icon={arrowBackFill}
          width={24}
          height={24}
          style={{ marginRight: '.5rem' }}
        />
        <Typography variant={'subtitle2'} fontWeight={500}>
          {`Back to ${isCandidate(user) ? 'Test Invites' : 'Previous Tests'}`}
        </Typography>
      </LinkStyle>
      <Stack
        direction={{ md: 'row' }}
        spacing={{ xs: '2rem', md: '2rem' }}
        sx={{
          justifyContent: 'space-between',
          mt: '2rem',
        }}
      >
        <Stack direction={'row'} sx={{ alignItems: 'center' }}>
          <Avatar
            alt={email}
            src={picture || ''}
            sx={{
              width: '4rem',
              height: '4rem',
            }}
          />
          <Stack direction={'column'} sx={{ ml: '1rem' }}>
            <Typography
              variant={'h6'}
              sx={{
                fontSize: '1rem',
                '@media (min-width:600px)': {
                  fontSize: '1.6667rem',
                },
                '@media (min-width:900px)': {
                  fontSize: '1.875rem',
                },
                '@media (min-width:1200px)': {
                  fontSize: '1.925rem',
                },
              }}
            >
              {name}
              {linkedin && (
                <Link
                  to={linkedin ?? ''}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: 'none',
                    marginTop: '0.25rem',
                    marginLeft: '0.25rem',
                    filter: 'grayscale(1)',
                  }}
                >
                  <Icon icon={linkedinIcon} width="16" height="16" />
                </Link>
              )}
            </Typography>
            <Stack sx={{ mt: '8px' }} direction={'row'}>
              <Typography variant={'subtitle2'} fontWeight={500}>
                Invited at:
              </Typography>
              <Chip
                label={formatDateToMonthDayYearTime(testData.invited_at)}
                variant="filled"
                size="small"
                sx={{ ml: '8px', mr: '8px' }}
              />
            </Stack>
          </Stack>
        </Stack>
        <Box
          sx={{
            mt: 1,
            justifyContent: { xs: 'flex-end', sm: 'flex-start' },
          }}
        >
          {!testData.submitted_at && (
            <Button
              size="medium"
              variant="contained"
              color="primary"
              endIcon={<ArrowForwardOutlinedIcon />}
              onClick={(event) => {
                event.stopPropagation();
                navigate(`/assessments/${testData.test.brokee_id}`);
              }}
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              Go to Testing
            </Button>
          )}
        </Box>
      </Stack>
      {showSnackbar && (
        <Snackbar
          open
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Alert variant="filled" onClose={handleSnackbarClose}></Alert>
        </Snackbar>
      )}

      {isCandidateUser ? (
        <CandidatePageDetailsBody
          testTrial={testData as PersonalTestTrialPartialFragment}
        />
      ) : (
        <PersonalPageDetailsBody
          testTrial={testData as PersonalTestTrialPartialFragment}
        />
      )}
    </Stack>
  );
}
