import {
  Box,
  Card,
  CardHeader,
  Container,
  Divider,
  Tab,
  Tabs,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetPublicRecordingsQuery } from '../../@generated/facadeClient';
import { formatToReadableDateTime } from '../../utils/common';
import AsciinemaPlayer from '../AsciinemaPlayer/AsciinemaPlayer';
import { ErrorPage } from '../ErrorDialog';
import CircularProgressPage from '../ProgressLoader/ProgressLoader';
import { TabPanel } from '../Tabpanel/TabPanel';
export default function PublicRecordsPage() {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();

  const [tabValue, setTabValue] = useState('0');
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const { data: recordings, loading: recordingsLoading } =
    useGetPublicRecordingsQuery({
      variables: {
        uid: params.id,
      },
    });

  if (recordingsLoading) return CircularProgressPage();
  if (!recordings || !recordings.public_recordings.length)
    return (
      <ErrorPage
        title="Failed to Fetch Recordings"
        onClose={() => {
          navigate('/');
        }}
        message="No recordings available for the provided link id"
      />
    );

  const records = recordings.public_recordings;

  return (
    <Container
      sx={{
        height: '100vh',
        width: '100vw',
        padding: 2.5,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Card
        elevation={3}
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CardHeader
          title="Recording Activity"
          subheader={`Available until ${formatToReadableDateTime(
            records[0].expires_at
          )}`}
          subheaderTypographyProps={{ sx: { mt: 0.5, fontSize: 14 } }}
        />
        <Divider />
        <Box pl={2.5}>
          {records.length !== 1 && (
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="asciinema-player"
              sx={{ flexShrink: 0 }}
            >
              {records.map((_, index) => (
                <Tab
                  label={`Tab ${index + 1}`}
                  value={`${index}`}
                  key={index}
                />
              ))}
            </Tabs>
          )}
          {records.map((record, index) => {
            return (
              <TabPanel
                value={tabValue}
                index={`${index}`}
                sx={{
                  pt: 1,
                }}
              >
                <AsciinemaPlayer
                  src={record.url}
                  preload={true}
                  terminalFontSize="medium"
                  fit="both"
                  // rows="24"
                  poster="npt:10:00"
                />
              </TabPanel>
            );
          })}
        </Box>
      </Card>
    </Container>
  );
}
