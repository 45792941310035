import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { generateSlug } from '../../../utils/common';
import LearningPathCard from './LearningPathCard';
import { useGetLearningPathsQuery } from '../../../@generated/facadeClient';

export default function LearningPathsPage() {
  const navigate = useNavigate();

  const { data, loading } = useGetLearningPathsQuery();

  const handleCardClick = (pathName: string) => {
    const slug = generateSlug(pathName);
    navigate(`${slug}`);
  };

  return (
    <Box
      sx={{
        mt: '1rem',
        mb: '3rem',
      }}
    >
      <Typography variant="h3" display="block" gutterBottom>
        Learning Paths
      </Typography>
      {loading && <LinearProgress />}

      <Grid container spacing={3} paddingTop={2.5}>
        {data?.learning_paths.map((item) => (
          <Grid item xs={12} sm={6} md={4} key={item.id}>
            <LearningPathCard
              itemAggregated={item.learning_paths_tests_aggregate}
              item={item}
              onCardClick={handleCardClick}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
