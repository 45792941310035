export enum ESubscriptionFeaturesList {
  CANDIDATE_PAGE = `By subscribing, you'll gain access to:
    All tests during the candidate invitation process
    Choose troubleshooting problems for candidates
    `,
  CANDIDATE_RESULT_DETAILS_PAGE = `By subscribing, you'll gain access to:
    AI-generated summaries of candidate performance
    AI-generated follow-up questions tailored to candidate activity
    Custom problem sets for candidates to use in troubleshooting
    `,

  INVITATION_LINK_PAGE = `By subscribing, you can quickly generate and share a single link to invite multiple candidates at once, streamlining your recruitment efforts.`,

  TEST_CATALOG_PAGE = `By subscribing, you gain access to:
    All available tests, not just those included in the free trial
    Comprehensive problem list for dynamic tests
    `,
  TEST_CATALOG_PERSONAL_PAGE = `By subscribing, you gain access to all tests, not just those available in the free trial`,

  TEST_INSIGHTS_PAGE = `By subscribing, you can view the most attempted and failed tests for your company, along with average completion times.`,

  TEAMS_PAGE = `By subscribing, you can invite unlimited admins to your company for seamless collaboration and efficient management.`,
}
