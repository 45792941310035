export const generalQuestions = [
  {
    text: 'Can I try Brokee for free?',
    answer:
      'Yes, we offer a Free Plan where you get 5 test credits to explore our offering.',
  },
  {
    text: 'How can I choose the right plan for me?',
    answer:
      'Consider your hiring needs and budget. The Free plan is ideal for exploring Brokee just enough to understand how the whole process is working. On Demand plan offers flexibility for occasional testing. The Growth plan is best for regular testing and integration with external systems, e.g. ATS (Applicant Tracking Systems).',
  },
  {
    text: 'What are test attempts and test credits?',
    answer:
      'When you buy tests, or subscribe to our monthly plan, you get some amount of test credits that can be used for skills assessments. 1 test credit = 1 test attempt by a candidate. So, if you have 5 credits, 5 different candidates can take 1 skill test, or you can send 5 skills tests to a single candidate. A test credit is used after the candidate submits a test. Invitations are free and unlimited',
  },
  {
    text: 'What means easy tests attempts?',
    answer:
      "Every skills assessment has specific difficulty-level assigned. Easy tests can usually be completed in 10-15 minutes and don't require complicated IT infrastructure setup, so we offer access to a selection of easy tests on a Free plan to explore our product. Some easy-to-complete tests do required more advanced infrastructure setup (e.g. creating temporary accounts on GCP, AWS, Azure), and those are not available on the Free plan",
  },
  {
    text: 'Do my test credits expire?',
    answer:
      'Any test credits you paid for do not expire. Even if you cancel monthly subscription, but you still have unused credits, you can use them in the future. The only exception to this rule are test credits on a free plan. They are offered as a bonus to explore Brokee, and they disappear whenever you switch to any paid plans.',
  },
  {
    text: 'What happens when I run out of credits?',
    answer:
      'It depends on your plan. If you are on a Free of On-Demand plans, you need to buy more test credits or switch to a monthly subscription to start new tests. If you are on a Growth Plan, you can keep using the platform, and any additional test runs will be charged to you at the end of the month.',
  },
  {
    text: 'What is Savings Dashboard?',
    answer:
      'Savings Dashboard gives you an overview of your hiring activities and tells you how much time and money your company is saving by using Brokee to evaluate technical skills of your hiring candidates. You can configure it according to your hiring process to get an accurate calculation, we provide industry averages out of the box.',
  },
  {
    text: 'Do you provide refunds?',
    answer:
      "Generally, we don't provide refunds, we provide a Free Plan instead, so you can try out Brokee before making a purchase decision. Also, we offer a cheap On-Demand plan where you can purchase a few tests to try more advanced functionality. We can make exceptions on a case by case basis if there was an error on our side.",
  },
];

export const personalAccountQuestions = [
  {
    text: 'Can I try Brokee for free?',
    answer:
      'Yes, we offer a Free Plan where you get 3 test credits to explore our offering.',
  },
  // {
  //   text: 'How can I choose the right plan for me?',
  //   answer:
  //     'The Free plan is ideal for exploring the Brokee just enough to understand how the whole process works. The On Demand Pack plan offers flexibility for occasional testing, similar to a pay-as-you-go model. The Personal plan is for regular testing based on a monthly plan.',
  // },
  {
    text: 'What are test attempts and test credits?',
    answer:
      'Test credits are your currency for running assessments on this platform. Each credit allows you to create a single testing environment. So, if you have 5 credits, you can run one test 5 times or 5 different tests once. A credit is consumed after successfully creating the environment for the test.',
  },
  {
    text: 'What means easy tests attempts?',
    answer:
      "Every skills assessment has specific difficulty-level assigned. Easy tests can usually be completed in 10-15 minutes and don't require complicated IT infrastructure setup, so we offer access to a selection of easy tests on a Free plan to explore our product. Some easy-to-complete tests do required more advanced infrastructure setup (e.g. creating temporary accounts on GCP, AWS, Azure), and those are not available on the Free plan",
  },
  // {
  //   text: 'Do my test credits expire?',
  //   answer:
  //     'Any test credits you paid for do not expire. Even if you cancel monthly subscription, but you still have unused credits, you can use them in the future. The only exception to this rule are test credits on a free plan. They are offered as a bonus to explore Brokee, and they disappear whenever you switch to any paid plans.',
  // },
  // {
  //   text: 'What happens when I run out of credits?',
  //   answer:
  //     "If you're on a Personal subscription and run out of credits, you have two options: 1. Wait for the next billing cycle: Your credits will automatically renew at the beginning of your next billing cycle. 2. Purchase additional credits on demand: We offer flexible On Demand credit packs that you can purchase instantly to continue testing.",
  // },
  {
    text: 'Do you provide refunds?',
    answer:
      "Generally, we don't provide refunds, we provide a Free Plan instead, so you can try out Brokee before making a purchase decision. We can make exceptions on a case by case basis if there was an error on our side.",
  },
];
