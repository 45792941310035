import { User } from '@auth0/auth0-react';
import { axiosInstance } from '../../utils/axios';
import {
  EFilterByResultKeys,
  ESortByKeys,
  defaultFilters,
  filterByResultMaps,
  sortByMap,
} from './TestsHistoryList/data';

export interface ICandidate {
  app_metadata: {
    company_id: number;
    company_name: string;
    requester: string;
    requester_id: number;
    test_id: string;
    test_name: string;
  };
  email: string;
  user_exists: boolean;
}

export interface ISendTestTrailRequestPayload {
  companyName: string;
  requesterName: string;
  requesterEmail: string;
  testId: string;
  testName: string;
  recipientEmail: string;
}

export interface ITestDescriptionCardProps {
  brokee_id: number;
  title: string | null;
  description: string | null;
  image: string;
  descriptionLong: string;
  userhash: string;
}

export const createUser = async (
  accessToken: string,
  {
    email,
    appMetadata,
    userMetadata,
  }: {
    email: string;
    appMetadata: Record<string, unknown>;
    userMetadata: Record<string, unknown>;
  }
): Promise<User> => {
  const { data } = await axiosInstance.post(
    '/api/admin/users',
    {
      email,
      app_metadata: appMetadata,
      user_metadata: userMetadata,
      password: crypto.randomUUID(),
      verify_email: false,
      connection: 'Username-Password-Authentication',
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );

  return data;
};

export const addAnotherTestForUser = async (
  accessToken: string,
  {
    userhash,
    test_id,
    company_id,
    requester_id,
    issues_per_test,
    available_issues,
    issues_list,
  }: {
    userhash: string;
    test_id: number;
    company_id: number;
    requester_id: number;
    issues_per_test: number;
    available_issues: number;
    issues_list: number[];
  }
): Promise<User> => {
  const { data } = await axiosInstance.postForm(
    '/api/results',
    {
      userhash,
      test_id,
      company_id,
      requester_id,
      issues_per_test,
      available_issues,
      issues_list: issues_list.toString(),
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );

  return data;
};

export const changePassword = async (
  accessToken: string,
  {
    email,
    testId,
    ttl = 14,
  }: {
    email: string;
    testId: string;
    ttl?: number;
  }
): Promise<string> => {
  const formData = new FormData();
  formData.append('email', email.toLowerCase());
  formData.append('test_id', testId);
  formData.append('ttl', ttl.toString());
  const { data } = await axiosInstance.post(
    '/api/admin/users/change_password',
    formData,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );

  return data;
};

export const sendTestTrialInvitation = async (
  accessToken: string,
  {
    companyName,
    requesterName,
    requesterEmail,
    testId,
    testName,
    recipientEmail,
  }: ISendTestTrailRequestPayload
): Promise<string> => {
  const { data } = await axiosInstance.postForm(
    '/api/results/email/send-test-trial-invitation',
    {
      company_name: companyName,
      requester_name: requesterName,
      requester_email: requesterEmail,
      test_id: testId,
      test_name: testName,
      recipient_email: recipientEmail,
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );

  return data;
};

export const inviteUser = async (
  accessToken: string,
  email: string
): Promise<undefined> => {
  const formData = new FormData();
  formData.append('user_email', email);
  const { data } = await axiosInstance.post(
    '/api/admin/users/invite',
    formData,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return data;
};

export const deleteUser = async (
  accessToken: string,
  userID: string
): Promise<undefined> => {
  const { data } = await axiosInstance.delete(`/api/admin/users/${userID}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return data;
};

export const getTestTrialsQueryVariables = ({
  limit,
  page,
  search,
  sort,
  filterByTest,
  filterByResult,
}: {
  limit: number;
  page: number;
  search: string;
  sort: ESortByKeys;
  filterByTest: string;
  filterByResult: EFilterByResultKeys;
}): any => ({
  variables: {
    filter: {
      ...defaultFilters,
      ...(filterByTest === 'All'
        ? {}
        : { test: { brokee_id: { _eq: parseInt(filterByTest, 10) } } }),
      ...filterByResultMaps[filterByResult],
      ...(!!search.length
        ? {
            candidate: {
              email: { _like: `%${search}%` },
            },
          }
        : {}),
    },
    order_by: sortByMap[sort] ?? null,
    limit,
    offset: limit * page,
  },
});

export const getTestTrialsCountQueryVariables = ({
  search,
  sort,
  filterByTest,
  filterByResult,
}: {
  search: string;
  sort: ESortByKeys;
  filterByTest: string;
  filterByResult: EFilterByResultKeys;
}): any => ({
  variables: {
    filter: {
      ...defaultFilters,
      ...(filterByTest === 'All'
        ? {}
        : { test: { brokee_id: { _eq: parseInt(filterByTest, 10) } } }),
      ...filterByResultMaps[filterByResult],
      ...(!!search.length
        ? {
            candidate: {
              email: { _like: `%${search}%` },
            },
          }
        : {}),
    },
    order_by: sortByMap[sort] ?? null,
  },
});

export interface ICompletionDetails {
  completed_tasks?: string[];
  failed_tasks?: string[];
  timing_info?: string;
}

export function isNumber(value: string | number | null | undefined): boolean {
  return typeof value === 'number' && isFinite(value);
}

export function isValidLimit(limit: number) {
  const availableLimits = [5, 10, 25];
  return availableLimits.includes(limit);
}

export function isValidPage(page: number) {
  return isNumber(page);
}
