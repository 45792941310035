export interface IAssessmentFeedbackFormProps {
  companyName: string;
  reviewerEmail: string;
  reviewerName: string;
  trialId: number;
  testName: string;
}
export const feedbackCharLimit = 700;

export const assessmentFeedbackLabels: { [index: string]: string } = {
  1: 'Very Dissatisfied',
  2: 'Dissatisfied',
  3: 'Neutral',
  4: 'Satisfied',
  5: 'Very Satisfied',
};

export function getLabelText(value: number) {
  return `${value} Star${value !== 1 ? 's' : ''}, ${
    assessmentFeedbackLabels[value]
  }`;
}
