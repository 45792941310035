import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import {
  Box,
  Container,
  Icon,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { Link, Outlet } from 'react-router-dom';
import PrivateFooter from '../../components/Footer/PrivateFooter';
import { Navbar } from '../../components/Navbar';
import { ProgressLoader } from '../../components/ProgressLoader';
import { Sidebar } from '../../components/Sidebar';
import { useBilling } from '../../hooks/useBilling';
import { ILayoutProps } from './data';
export default function CompanyLayout({
  isSidebarOpen,
  menuItems,
  handleSidebarOpen,
  handleSidebarClose,
}: ILayoutProps) {
  const {
    getAvailableCredits,
    companyAccessDetails,
    companyAccessDetailsLoading,
    isFreeTrialRegistration,
    isUnlimitedRegularPlan,
    canAccessProFeatures,
  } = useBilling();
  const availableCredits = getAvailableCredits();

  if (
    (!companyAccessDetails || companyAccessDetailsLoading) &&
    !isFreeTrialRegistration()
  ) {
    return <ProgressLoader />;
  }
  const expirationSubDate = new Date(
    companyAccessDetails?.subscription_expiration_date || ''
  );

  return (
    <>
      <Box
        sx={(theme: Theme) => ({
          display: 'flex',
          flex: '1 1 auto',
          maxWidth: '100%',
          paddingTop: '4rem',
          [theme.breakpoints.up('lg')]: {
            paddingLeft: '17.5rem',
          },
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Container sx={{ py: '2rem', pb: '1rem', minHeight: '100vh' }}>
            <Outlet />
          </Container>
          <PrivateFooter />
        </Box>
      </Box>
      <Navbar
        onSidebarOpen={handleSidebarOpen}
        extraInfo={
          <Typography variant="body2" color={'text.secondary'}>
            <Link
              id="navbar-current-credits"
              to={`/company/settings/subscription`}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              {availableCredits <= 0 && canAccessProFeatures() ? (
                <Tooltip
                  title={
                    isUnlimitedRegularPlan()
                      ? `Unlimited access until ${expirationSubDate.toDateString()}`
                      : 'Unlimited Credits with Tiered Pricing'
                  }
                >
                  <span>
                    <Icon color="primary">
                      <AllInclusiveIcon />
                    </Icon>
                  </span>
                </Tooltip>
              ) : (
                <>
                  <b>{availableCredits}</b> Credit
                  {availableCredits === 1 ? '' : 's'} left
                </>
              )}
            </Link>
          </Typography>
        }
      />
      <Sidebar
        onClose={handleSidebarClose}
        open={isSidebarOpen}
        menuItems={menuItems}
      />
    </>
  );
}
