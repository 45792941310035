import { ApolloClient, useApolloClient } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import Bugsnag, { NotifiableError } from '@bugsnag/js';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import MenuIcon from '@mui/icons-material/Menu';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import RuleOutlinedIcon from '@mui/icons-material/RuleOutlined';
import TroubleshootOutlinedIcon from '@mui/icons-material/TroubleshootOutlined';
import {
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Toolbar,
  Tooltip,
} from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetTrialIssuesDocument } from '../../@generated/facadeClient';
import {
  GetTrialIssuesQuery,
  GetUserAssessmentDataQuery,
} from '../../@generated/types';
import { useInfrastructure } from '../../company/AssessmentPage/InfrastructureContext';
import {
  ICurrentProgress,
  checkSolution,
  submitAssessment,
} from '../../company/AssessmentPage/Services/ChallengeService';
import { iconButtonStyle } from '../../company/AssessmentPage/data';
import { EAssessmentErrors } from '../../constants/assessment';
import { ASSESSMENT_DRAWER_WIDTH } from '../../constants/sizes';
import { getUserHash, getUserTerminalSecret } from '../../utils/auth';
import { AccountPopover } from '../AccountPopover';
import { AssessmentAlert } from '../AssessmentAlert';
import CheckSolutionAlert from '../AssessmentAlert/CheckSolutionAlert';
import LinearProgressWithLabel from '../ProgressLoader/ProgressLoaderWithLabel';
import Countdown from './Countdown';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: ASSESSMENT_DRAWER_WIDTH,
    width: `calc(100% - ${ASSESSMENT_DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const fetchIssues = async (
  client: ApolloClient<object>,
  trialId: number
): Promise<number[]> => {
  try {
    const { data }: { data: GetTrialIssuesQuery } = await client.query({
      query: GetTrialIssuesDocument,
      variables: { trialId },
    });
    const issueIDs = data.test_trial_issues
      .map((issue) => issue.test_issue_id)
      .filter((id): id is number => id !== null && id !== undefined);

    return issueIDs;
  } catch (error) {
    Bugsnag.notify(error as NotifiableError);
    return [];
  }
};

export default function Navbar({
  open,
  extraInfo,
  onSidebarOpen,
  assessment,
}: {
  open?: boolean;
  extraInfo?: ReactNode;
  onSidebarOpen(): void;
  assessment: GetUserAssessmentDataQuery['tests'][0] | null;
}) {
  const client = useApolloClient();
  const navigate = useNavigate();
  const { user, getAccessTokenSilently } = useAuth0();
  const {
    createInfrastructure,
    isInfrastructureCreated,
    isInfrastructureCreating,
    isInfraError,
    infrastructureCreationTime,
    infraLoading,
    infrastructure,
    initialFetchInfrastructure,
    startPollingInfrastructure,
  } = useInfrastructure();

  const [openSubmitDialog, setOpenSubmitDialog] = useState(false);
  const [openProgressAlert, setOpenProgressAlert] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [checkingSolution, setCheckingSolution] = useState(false);
  const [currentProgress, setCurrentProgress] =
    useState<ICurrentProgress | null>(null);
  const [checkSolutionError, setCheckSolutionError] = useState(false);

  const handleAlertClick = () => {
    setAlertOpen(true);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
    setSubmissionError(false);
    setCheckingSolution(false);
  };

  const assessmentId = assessment?.brokee_id || 0;

  const companyID = sessionStorage.getItem('company_id');
  const trialId = Number(sessionStorage.getItem('trial_id'));

  // uuid can be undefined, when 0 trials, it'll be set again after new trial insertion
  const uuid = assessment?.test_trials[0]?.uuid;

  const userHash = getUserHash(user);
  const terminalSecret = getUserTerminalSecret(user);

  const handleSubmissionDialog = async () => {
    setOpenSubmitDialog(true);
  };

  const handleCloseSubmissionDialog = () => {
    setOpenSubmitDialog(false);
  };

  const handleCloseProgressAlert = () => {
    setCheckingSolution(false);
    setOpenProgressAlert(false);
    setTimeout(() => {
      setCheckingSolution(false);
    }, 10 * 1000);
  };

  const handleEnvironmentCreation = async () => {
    try {
      const issues = await fetchIssues(client, trialId);
      createInfrastructure(
        String(assessmentId),
        assessment?.environment_type?.type || '',
        assessment?.test_category?.name || '',
        String(companyID),
        terminalSecret,
        issues,
        assessment?.dynamic_issues || false,
        user?.email,
        userHash,
        uuid
      );
    } catch (error) {
      Bugsnag.notify(error as NotifiableError);
    }
  };

  useEffect(() => {
    initialFetchInfrastructure(userHash, String(assessmentId)).then(() => {
      if (!isInfrastructureCreated && isInfrastructureCreating) {
        startPollingInfrastructure(userHash, String(assessmentId));
      }
    });
  }, [
    startPollingInfrastructure,
    initialFetchInfrastructure,
    isInfrastructureCreated,
    isInfrastructureCreating,
    userHash,
    assessmentId,
  ]);

  const handleCheckSolution = async () => {
    try {
      setCheckingSolution(true);
      setCheckSolutionError(false);
      const accessToken = await getAccessTokenSilently();
      const progressData = await checkSolution(accessToken, {
        userhash: userHash,
        test_id: assessmentId,
      });
      setCurrentProgress(progressData);
      setOpenProgressAlert(true);
    } catch (error) {
      setCheckingSolution(false);
      setOpenProgressAlert(false);
      setCheckSolutionError(true);
      Bugsnag.notify(error as NotifiableError);
    }
  };

  const handleConfirmSubmission = async () => {
    try {
      setSubmitting(true);
      setOpenSubmitDialog(false);
      const accessToken = await getAccessTokenSilently();
      await submitAssessment(accessToken, {
        userhash: userHash,
        test_id: assessmentId,
        trial_id: trialId || null,
      });
      navigate(`/assessments/submission-results/${trialId}`);
    } catch (error) {
      setSubmitting(false);
      setSubmissionError(true);
      setOpenSubmitDialog(false);
      Bugsnag.notify(error as NotifiableError);
    }
  };
  const errorMessage = (() => {
    if (isInfraError) return EAssessmentErrors.INFRA_CREATING;
    if (submissionError) return EAssessmentErrors.SUBMIT_ASSESSMENT;
    if (checkSolutionError) return EAssessmentErrors.CHECKING_SOLUTION;
    return '';
  })();

  return (
    <>
      <AppBar
        position="fixed"
        open={open}
        sx={(theme) => ({
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows[3],
        })}
      >
        <Toolbar
          sx={(theme) => ({
            boxShadow: theme.shadows[3],
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '65px',
            minHeight: '65px',
            overflow: 'hidden',
          })}
        >
          {!open && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              id="assessment-page-show-side-bar-menu"
              onClick={onSidebarOpen}
              edge="start"
              size="large"
              sx={{
                color: 'neutral.900',
              }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 2,
              flexGrow: 1,
              overflowX: 'auto',
              paddingLeft: '6rem',
            }}
          >
            <Chip
              avatar={
                <Avatar
                  alt="Challenge Image"
                  src={assessment?.featured_image || ''}
                  variant="rounded"
                />
              }
              label={
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    flexGrow: 1,
                    minWidth: 0,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <Box
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {assessment?.name}
                  </Box>
                  {isInfrastructureCreated && (
                    <>
                      <Divider orientation="vertical" flexItem />
                      <Countdown
                        startTime={infrastructureCreationTime?.getTime() || 0}
                      />
                      <Tooltip
                        title="Your environment is ready. Click to switch and start troubleshooting."
                        placement="bottom"
                      >
                        <span>
                          <IconButton
                            id="assessment-page-navigate-to-env"
                            onClick={() =>
                              navigate(
                                `/assessments/${assessmentId}/environment`
                              )
                            }
                            size="medium"
                            color="primary"
                          >
                            <TroubleshootOutlinedIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </>
                  )}
                </Box>
              }
              variant="outlined"
              sx={{
                fontSize: 15,
                fontWeight: 'bold',
                color: 'neutral.900',
                padding: '1.25rem',
                borderRadius: 3,
                display: 'flex',
                alignItems: 'center',
                maxWidth: '100%',
              }}
            />
            <Tooltip title="Begin the assessment">
              <span>
                <Button
                  id="assessment-page-begin-assessment"
                  aria-label="start"
                  size="large"
                  variant="text"
                  startIcon={<PlayCircleOutlineIcon />}
                  disabled={
                    isInfrastructureCreated ||
                    infraLoading ||
                    isInfrastructureCreating
                  }
                  onClick={handleEnvironmentCreation}
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: 'black',
                    borderRadius: 5,
                  }}
                >
                  Begin
                </Button>
              </span>
            </Tooltip>
            <Tooltip title="Check current solution">
              <span>
                <IconButton
                  aria-label="check"
                  id="assessment-page-check-solution"
                  disabled={
                    !isInfrastructureCreated || checkingSolution || submitting
                  }
                  onClick={handleCheckSolution}
                  size="medium"
                  sx={iconButtonStyle}
                >
                  <RuleOutlinedIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Finish and submit my solution">
              <span>
                <IconButton
                  aria-label="submit"
                  id="assessment-page-submit-solution"
                  disabled={!isInfrastructureCreated || submitting}
                  onClick={handleSubmissionDialog}
                  size="medium"
                  sx={iconButtonStyle}
                >
                  <CheckCircleOutlineIcon />
                </IconButton>
              </span>
            </Tooltip>
            {(isInfraError || submissionError || checkSolutionError) && (
              <>
                <IconButton
                  onClick={handleAlertClick}
                  id="assessment-page-show-alert-error"
                  aria-label="error"
                  color="error"
                  size="medium"
                  sx={{ mr: 1, backgroundColor: 'error.light' }}
                >
                  <ErrorOutlineIcon />
                </IconButton>
                <AssessmentAlert
                  text={errorMessage}
                  duration={10000}
                  open={alertOpen}
                  onClose={handleAlertClose}
                />
              </>
            )}
            <CheckSolutionAlert
              progress={currentProgress}
              open={openProgressAlert}
              onClose={handleCloseProgressAlert}
            />
            <Dialog
              open={openSubmitDialog}
              onClose={handleCloseSubmissionDialog}
            >
              <DialogTitle>Confirm Submission</DialogTitle>
              <DialogContent>
                <p>
                  Your assessment results will now be submitted for evaluation.
                  This action concludes your current attempt. Do you want to
                  confirm?
                </p>
              </DialogContent>
              <DialogActions>
                <Button
                  id="assessment-page-submit-solution-cancel"
                  onClick={handleCloseSubmissionDialog}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleConfirmSubmission}
                  id="assessment-page-submit-solution-confirm"
                  variant="contained"
                  color="primary"
                >
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
          <AccountPopover />
        </Toolbar>
        {(submitting ||
          infraLoading ||
          isInfrastructureCreating ||
          checkingSolution) && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress color="success" />
          </Box>
        )}
        {(isInfraError || submissionError || checkSolutionError) && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress color="error" />
          </Box>
        )}
        {isInfrastructureCreating && (
          <Box
            sx={{
              position: 'fixed',
              top: '65px',
              left: open ? `${ASSESSMENT_DRAWER_WIDTH}px` : 0,
              width: open
                ? `calc(100% - ${ASSESSMENT_DRAWER_WIDTH}px)`
                : '100%',
              zIndex: 1200,
              transition: 'left 0.1s, width 0.1s',
            }}
          >
            <LinearProgressWithLabel
              color="success"
              available_resources={infrastructure?.resourceCount || 0}
              total_resources={assessment?.infra_total_resources || 0}
            />
          </Box>
        )}
      </AppBar>
    </>
  );
}
