import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Test_Tech_Services_Resources } from '../../@generated/types';

export default function LearningResourcesView({
  resources,
}: {
  resources: Test_Tech_Services_Resources[];
}) {
  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <>
      {resources.map((resource, index) => (
        <Accordion
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          // sx={{ borderRadius: 1.5, marginBottom: 1 }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}bh-content`}
            id={`panel${index}bh-header`}
          >
            <Typography
              variant="subtitle1"
              sx={{ width: '90%', flexShrink: 0 }}
            >
              {resource.tech_service?.name}:{' '}
              {resource.tech_service_component?.name}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography ml={2} variant="subtitle2">
              {resource.tech_service_component?.description}
            </Typography>
            {resource.tech_service_component_resources.length > 0 ? (
              <Box ml={4}>
                {resource.tech_service_component_resources.map(
                  (res, resIndex) => (
                    <Box key={resIndex} sx={{ marginTop: 1 }}>
                      <Typography variant="subtitle2" component="span">
                        {res.tech_resource?.name}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component={RouterLink}
                        to={res.tech_resource?.url || ''}
                        target="_blank"
                        sx={{
                          display: 'block',
                          marginTop: 0.5,
                          textDecoration: 'none',
                          overflowWrap: 'break-word',
                          wordBreak: 'break-all',
                          whiteSpace: 'normal',
                        }}
                      >
                        {res.tech_resource?.url}
                      </Typography>
                    </Box>
                  )
                )}
              </Box>
            ) : (
              <Typography variant="subtitle2" ml={2}>
                No resources provided
              </Typography>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}
